<template>
    <div class="pb-5">
        <v-title title="Permissions - Add"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add permission</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <mercur-card class="mt-n4 mb-4">
                            <mercur-input v-model="form.permissionName" :class="getValidationClass($v, 'permissionName')">
                                Permission name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.permissionName.required">Permission name is required</span>
                                </template>
                            </mercur-input>
                        </mercur-card>
                        <mercur-card class="mb-4 card--scrollable">
                            <div class="row">
                                <div class="col-8 d-flex align-items-center">
                                    <h2 class="mt-1 font-weight-normal">Actions</h2>
                                </div>
                                <div class="col-4">
                                    <mercur-input v-if="filteredActions.length" v-model="searchPhrase">
                                        <template #prefix><i class="fas fa-search"></i></template>
                                    </mercur-input>
                                </div>
                            </div>
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Action override</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="action in filteredActions" :key="action.actionId">
                                    <td>{{ action.actionName }}</td>
                                    <td>
                                        <mercur-checkbox v-model="action.actionStatus" :id="`action-${action.actionId}`">Is allowed</mercur-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </mercur-card>
                        <div class="text-right mt-4">
                            <mercur-button class="btn" to="/permissions">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add permission</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AddPermission',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: CONFIG.API.ROUTES.ACTIONS.OVERVIEW,
            action: CONFIG.API.ROUTES.PERMISSIONS.ADD,
            form: {
                actions: {},
            },
            searchPhrase: '',
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            permissionName: {
                required,
            },
        },
    },
    computed: {
        selectedActions () {
            let arr = []
            this.items.forEach((item) => {
                if (item.actionStatus) {
                    arr.push(item.actionId)
                }
            })
            return arr
        },
        filteredActions () {
            const search = this.searchPhrase.toLowerCase().trim()

            if (!search) {
                return this.items
            }

            return this.items.filter(action => action.actionName.toLowerCase().indexOf(search) > -1)
        },
    },
    methods: {
        submitForm () {
            this.form.actions.actionIds = this.selectedActions

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Permission was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'PermissionsWrapper',
                    })
                }, 1000)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .card--scrollable {
        height: calc(100vh - 400px);
        overflow-y: auto;
    }
</style>
